<div #home class="container-fluid" [@.disabled]='animationsDisabled' style="background-image: linear-gradient(to top, #000, #056674); background-attachment: fixed;">
  <nav class="navbar navbar-expand-lg navbar-dark bg-transparent fixed-bottom" id="navbar">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarNav" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" [collapse]="isCollapsed" [isAnimated]="true">
        <div class="navbar-nav">
            <a [class]="activeNavLink=='home' ? 'nav-link text-secondary active' : 'nav-link text-secondary'" (click)="scroll(home)">AlecKayetion</a>
            <a [class]="activeNavLink=='contact' ? 'nav-link active' : 'nav-link'" (click)="scroll(contact)">Contact</a>
        </div>
    </div>
  </nav>
  <app-home></app-home>
  <div #contact>
    <app-contact></app-contact>
  </div>
  <div class="text-center">
    <span>Copyright © {{ now | date:'yyyy' }} AlecKayetion Inc. All rights reserved.</span>
  </div>
</div>