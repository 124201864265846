<div class="container">
    <div class="row vh-100 align-items-center justify-content-center">
        <div class="col-md-6">
            <div class="card bg-transparent border border-secondary shadow">
                <div class="card-body">
                    <h1 class="card-title">Pricing</h1>
                    <p class="card-text">Due to the customization available, the price of a service can range from $1,000 to $10,000.
                        Fill out the form below to contact us, so that we can better serve you.</p>
                </div>
            </div>
        </div>
    </div>
</div>
